exports.components = {
  "component---src-articles-how-to-guides-managing-content-how-to-create-a-shoppable-video-md": () => import("./../../../src/articles/how-to-guides/managing-content/how-to-create-a-shoppable-video.md" /* webpackChunkName: "component---src-articles-how-to-guides-managing-content-how-to-create-a-shoppable-video-md" */),
  "component---src-articles-how-to-guides-managing-content-how-to-use-the-cm-product-catalog-md": () => import("./../../../src/articles/how-to-guides/managing-content/how-to-use-the-cm-product-catalog.md" /* webpackChunkName: "component---src-articles-how-to-guides-managing-content-how-to-use-the-cm-product-catalog-md" */),
  "component---src-articles-protected-release-notes-engagement-cloud-js": () => import("./../../../src/articles/protected/release-notes/engagement-cloud.js" /* webpackChunkName: "component---src-articles-protected-release-notes-engagement-cloud-js" */),
  "component---src-articles-services-event-hub-service-event-hub-filter-test-page-js": () => import("./../../../src/articles/services/event-hub-service/event-hub-filter-test-page.js" /* webpackChunkName: "component---src-articles-services-event-hub-service-event-hub-filter-test-page-js" */),
  "component---src-articles-trails-import-content-mdx": () => import("./../../../src/articles/trails/importContent.mdx" /* webpackChunkName: "component---src-articles-trails-import-content-mdx" */),
  "component---src-concepts-discover-api-ckeditor-5-examples-mdx": () => import("./../../../src/concepts/discover/api/ckeditor5-examples.mdx" /* webpackChunkName: "component---src-concepts-discover-api-ckeditor-5-examples-mdx" */),
  "component---src-concepts-discover-api-commerce-api-mdx": () => import("./../../../src/concepts/discover/api/commerce-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-commerce-api-mdx" */),
  "component---src-concepts-discover-api-content-hub-api-mdx": () => import("./../../../src/concepts/discover/api/content-hub-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-content-hub-api-mdx" */),
  "component---src-concepts-discover-api-feedback-hub-api-mdx": () => import("./../../../src/concepts/discover/api/feedback-hub-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-feedback-hub-api-mdx" */),
  "component---src-concepts-discover-api-headless-server-api-mdx": () => import("./../../../src/concepts/discover/api/headless-server-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-headless-server-api-mdx" */),
  "component---src-concepts-discover-api-personalization-client-api-mdx": () => import("./../../../src/concepts/discover/api/personalization-client-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-personalization-client-api-mdx" */),
  "component---src-concepts-discover-api-personalization-server-api-mdx": () => import("./../../../src/concepts/discover/api/personalization-server-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-personalization-server-api-mdx" */),
  "component---src-concepts-discover-api-uapi-typescript-node-mdx": () => import("./../../../src/concepts/discover/api/uapi-typescript-node.mdx" /* webpackChunkName: "component---src-concepts-discover-api-uapi-typescript-node-mdx" */),
  "component---src-concepts-discover-api-unified-api-mdx": () => import("./../../../src/concepts/discover/api/unified-api.mdx" /* webpackChunkName: "component---src-concepts-discover-api-unified-api-mdx" */),
  "component---src-concepts-discover-components-coremedia-architecture-mdx": () => import("./../../../src/concepts/discover/components/coremedia-architecture.mdx" /* webpackChunkName: "component---src-concepts-discover-components-coremedia-architecture-mdx" */),
  "component---src-concepts-discover-components-coremedia-contenttypes-mdx": () => import("./../../../src/concepts/discover/components/coremedia-contenttypes.mdx" /* webpackChunkName: "component---src-concepts-discover-components-coremedia-contenttypes-mdx" */),
  "component---src-concepts-discover-components-coremedia-hubs-mdx": () => import("./../../../src/concepts/discover/components/coremedia-hubs.mdx" /* webpackChunkName: "component---src-concepts-discover-components-coremedia-hubs-mdx" */),
  "component---src-concepts-discover-services-campaigns-mdx": () => import("./../../../src/concepts/discover/services/campaigns.mdx" /* webpackChunkName: "component---src-concepts-discover-services-campaigns-mdx" */),
  "component---src-concepts-discover-services-event-hub-mdx": () => import("./../../../src/concepts/discover/services/event-hub.mdx" /* webpackChunkName: "component---src-concepts-discover-services-event-hub-mdx" */),
  "component---src-concepts-discover-services-image-transformation-mdx": () => import("./../../../src/concepts/discover/services/image-transformation.mdx" /* webpackChunkName: "component---src-concepts-discover-services-image-transformation-mdx" */),
  "component---src-concepts-discover-services-ingestservice-api-mdx": () => import("./../../../src/concepts/discover/services/ingestservice-api.mdx" /* webpackChunkName: "component---src-concepts-discover-services-ingestservice-api-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analytics-dashboards-index-mdx": () => import("./../../../src/pages/analytics/dashboards/index.mdx" /* webpackChunkName: "component---src-pages-analytics-dashboards-index-mdx" */),
  "component---src-pages-analytics-index-mdx": () => import("./../../../src/pages/analytics/index.mdx" /* webpackChunkName: "component---src-pages-analytics-index-mdx" */),
  "component---src-pages-analytics-managing-aggregation-panels-index-mdx": () => import("./../../../src/pages/analytics/managing-aggregation-panels/index.mdx" /* webpackChunkName: "component---src-pages-analytics-managing-aggregation-panels-index-mdx" */),
  "component---src-pages-analytics-managing-listing-panels-index-mdx": () => import("./../../../src/pages/analytics/managing-listing-panels/index.mdx" /* webpackChunkName: "component---src-pages-analytics-managing-listing-panels-index-mdx" */),
  "component---src-pages-analytics-reference-aggregation-panels-index-mdx": () => import("./../../../src/pages/analytics/reference/aggregation-panels/index.mdx" /* webpackChunkName: "component---src-pages-analytics-reference-aggregation-panels-index-mdx" */),
  "component---src-pages-analytics-reference-configuration-windows-index-mdx": () => import("./../../../src/pages/analytics/reference/configuration-windows/index.mdx" /* webpackChunkName: "component---src-pages-analytics-reference-configuration-windows-index-mdx" */),
  "component---src-pages-analytics-reference-index-mdx": () => import("./../../../src/pages/analytics/reference/index.mdx" /* webpackChunkName: "component---src-pages-analytics-reference-index-mdx" */),
  "component---src-pages-analytics-reference-listing-panels-index-mdx": () => import("./../../../src/pages/analytics/reference/listing-panels/index.mdx" /* webpackChunkName: "component---src-pages-analytics-reference-listing-panels-index-mdx" */),
  "component---src-pages-analytics-reference-live-panels-index-mdx": () => import("./../../../src/pages/analytics/reference/live-panels/index.mdx" /* webpackChunkName: "component---src-pages-analytics-reference-live-panels-index-mdx" */),
  "component---src-pages-discover-api-js": () => import("./../../../src/pages/discover/api.js" /* webpackChunkName: "component---src-pages-discover-api-js" */),
  "component---src-pages-discover-components-js": () => import("./../../../src/pages/discover/components.js" /* webpackChunkName: "component---src-pages-discover-components-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-discover-services-js": () => import("./../../../src/pages/discover/services.js" /* webpackChunkName: "component---src-pages-discover-services-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-how-to-guides-backend-development-index-mdx": () => import("./../../../src/pages/how-to-guides/backend-development/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-backend-development-index-mdx" */),
  "component---src-pages-how-to-guides-frontend-development-index-mdx": () => import("./../../../src/pages/how-to-guides/frontend-development/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-frontend-development-index-mdx" */),
  "component---src-pages-how-to-guides-index-mdx": () => import("./../../../src/pages/how-to-guides/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-index-mdx" */),
  "component---src-pages-how-to-guides-managing-content-index-mdx": () => import("./../../../src/pages/how-to-guides/managing-content/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-managing-content-index-mdx" */),
  "component---src-pages-how-to-guides-monitoring-and-logging-index-mdx": () => import("./../../../src/pages/how-to-guides/monitoring-and-logging/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-monitoring-and-logging-index-mdx" */),
  "component---src-pages-how-to-guides-quick-start-index-mdx": () => import("./../../../src/pages/how-to-guides/quick-start/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-quick-start-index-mdx" */),
  "component---src-pages-how-to-guides-security-index-mdx": () => import("./../../../src/pages/how-to-guides/security/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-security-index-mdx" */),
  "component---src-pages-how-to-guides-studio-development-index-mdx": () => import("./../../../src/pages/how-to-guides/studio-development/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-studio-development-index-mdx" */),
  "component---src-pages-how-to-guides-studio-fundamentals-index-mdx": () => import("./../../../src/pages/how-to-guides/studio-fundamentals/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-studio-fundamentals-index-mdx" */),
  "component---src-pages-how-to-guides-upgrades-index-mdx": () => import("./../../../src/pages/how-to-guides/upgrades/index.mdx" /* webpackChunkName: "component---src-pages-how-to-guides-upgrades-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-protected-application-reference-index-mdx": () => import("./../../../src/pages/protected/application-reference/index.mdx" /* webpackChunkName: "component---src-pages-protected-application-reference-index-mdx" */),
  "component---src-pages-protected-application-reference-teams-app-reference-index-mdx": () => import("./../../../src/pages/protected/application-reference/teams-app-reference/index.mdx" /* webpackChunkName: "component---src-pages-protected-application-reference-teams-app-reference-index-mdx" */),
  "component---src-pages-protected-contact-center-and-analytics-studio-index-mdx": () => import("./../../../src/pages/protected/contact-center-and-analytics-studio/index.mdx" /* webpackChunkName: "component---src-pages-protected-contact-center-and-analytics-studio-index-mdx" */),
  "component---src-pages-protected-contact-center-and-analytics-studio-teams-app-index-mdx": () => import("./../../../src/pages/protected/contact-center-and-analytics-studio/teams-app/index.mdx" /* webpackChunkName: "component---src-pages-protected-contact-center-and-analytics-studio-teams-app-index-mdx" */),
  "component---src-pages-protected-index-mdx": () => import("./../../../src/pages/protected/index.mdx" /* webpackChunkName: "component---src-pages-protected-index-mdx" */),
  "component---src-pages-protected-release-notes-index-mdx": () => import("./../../../src/pages/protected/release-notes/index.mdx" /* webpackChunkName: "component---src-pages-protected-release-notes-index-mdx" */),
  "component---src-pages-reference-materials-index-js": () => import("./../../../src/pages/reference-materials/index.js" /* webpackChunkName: "component---src-pages-reference-materials-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-campaigns-developer-guide-index-mdx": () => import("./../../../src/pages/services/campaigns/developer-guide/index.mdx" /* webpackChunkName: "component---src-pages-services-campaigns-developer-guide-index-mdx" */),
  "component---src-pages-services-campaigns-index-mdx": () => import("./../../../src/pages/services/campaigns/index.mdx" /* webpackChunkName: "component---src-pages-services-campaigns-index-mdx" */),
  "component---src-pages-services-campaigns-user-guide-index-mdx": () => import("./../../../src/pages/services/campaigns/user-guide/index.mdx" /* webpackChunkName: "component---src-pages-services-campaigns-user-guide-index-mdx" */),
  "component---src-pages-services-contact-center-and-analytics-studio-audios-app-index-mdx": () => import("./../../../src/pages/services/contact-center-and-analytics-studio/audios-app/index.mdx" /* webpackChunkName: "component---src-pages-services-contact-center-and-analytics-studio-audios-app-index-mdx" */),
  "component---src-pages-services-contact-center-and-analytics-studio-index-mdx": () => import("./../../../src/pages/services/contact-center-and-analytics-studio/index.mdx" /* webpackChunkName: "component---src-pages-services-contact-center-and-analytics-studio-index-mdx" */),
  "component---src-pages-services-engagement-cloud-index-mdx": () => import("./../../../src/pages/services/engagement-cloud/index.mdx" /* webpackChunkName: "component---src-pages-services-engagement-cloud-index-mdx" */),
  "component---src-pages-services-event-hub-service-index-mdx": () => import("./../../../src/pages/services/event-hub-service/index.mdx" /* webpackChunkName: "component---src-pages-services-event-hub-service-index-mdx" */),
  "component---src-pages-services-image-transformation-index-mdx": () => import("./../../../src/pages/services/image-transformation/index.mdx" /* webpackChunkName: "component---src-pages-services-image-transformation-index-mdx" */),
  "component---src-pages-services-index-mdx": () => import("./../../../src/pages/services/index.mdx" /* webpackChunkName: "component---src-pages-services-index-mdx" */),
  "component---src-pages-services-ingest-service-index-mdx": () => import("./../../../src/pages/services/ingest-service/index.mdx" /* webpackChunkName: "component---src-pages-services-ingest-service-index-mdx" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whats-new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */),
  "component---src-pages-whats-new-whats-new-filter-js": () => import("./../../../src/pages/whats-new/whats-new-filter.js" /* webpackChunkName: "component---src-pages-whats-new-whats-new-filter-js" */),
  "component---src-templates-article-asciidoc-js": () => import("./../../../src/templates/article-asciidoc.js" /* webpackChunkName: "component---src-templates-article-asciidoc-js" */)
}

