import React from 'react'
import styled from "styled-components"
import Sidebar from "../Sidebar";
import Header from "../Header";
import {Container, Row, Col} from 'react-grid-system';
import Footer from "../Footer";
import Prism from 'prismjs'
import '../../prism.css'
import {useMatch} from "@gatsbyjs/reach-router"
import Seo from "../SEO";
import clsx from "clsx";
import FullScreenContainer from "../FullScreenContainer";
import ReleaseContext from '../../contexts/release-context'
import Visible from '../Responsive/Visible'
import Hidden from '../Responsive/Hidden'
import { useStaticQuery, graphql } from 'gatsby'

Prism.manual = true;

const Style = styled.div`
  .sidebar {
    width: 22rem; /* Adjust the width as needed */
    background-color: ${props => props.theme.palette.gray[ '500' ]};
  }

  .root {
    background-color: #ffffff;

    main {
      min-height: calc(100vh - 52px);
    }
  }

  main {
    min-height: calc(100vh - 48px - 48px + 44px);
    box-sizing: border-box;
    padding: 160px 0;
  }

  .abstract {
    font-size: 20px;
    color: #787878;
    margin-bottom: 3rem;
  }

  .article {

    font-weight: 300;

    h1 {
      font-weight: 500;
    }

    h2, h3, h4, h5, h6 {
      font-weight: 300;
      margin-top: 4rem;
    }

    strong {
      font-weight: 500;
    }

    img {
      max-width: 100%;
    }

    .admonitionblock + .paragraph {
      margin-top: 24px;
    }

    span.image img {
      margin-top: 12px;
    }

    .article img {
      box-shadow: 0 0.6px 1.3px rgba(0, 0, 0, 0.15),
      0 5px 10px rgba(0, 0, 0, 0.3);
    }

    .by, .date, .categories {
      font-size: 12px;
      text-transform: uppercase;
      color: #787878;
      line-height: 150%;

      display: flex;
      flex-direction: row;
      align-items: center;

      > * + * {
        padding-left: 6px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    blockquote, .admonitionblock {
      background-color: #FFFCCC;
      border-radius: 12px;
      padding: 18px 24px 18px 14px;
      border-left: solid 10px #EFDF0F;
      margin-left: -24px;
      margin-right: -24px;

      p:last-child {
        margin: 0;
      }
    }

    .admonitionblock {
      table {
        margin: 0;

        td {
          border: none;

          &.icon {
            display: none;
          }

          &.content {
            padding: 0;
          }
        }

        .paragraph + .paragraph {
          margin-top: 1rem;
        }
      }
    }

    pre {
      padding: 36px 30px 30px;
      margin: 24px -20px;
      font-size: .9em;
      border-radius: 6px;
      overflow: auto;

      code {
        padding-right: 30px;
      }
    }

    .listingblock + .colist {
      margin-top: -24px;
    }

    div.code-toolbar {
      position: relative;
    }

    div.code-toolbar > .toolbar {
      position: absolute;
      top: 4px;
      right: 0;
      opacity: .3;

      .toolbar-item + .toolbar-item {
        margin-left: 1em;
      }

      .toolbar-item:nth-child(2) {
        > button {
          cursor: pointer;
        }
      }
    }

    div.code-toolbar:hover > .toolbar {
      opacity: 1;
    }

    /* Separate line b/c rules are thrown out if selector is invalid.
       IE11 and old Edge versions don't support :focus-within. */

    div.code-toolbar:focus-within > .toolbar {
      opacity: 1;
    }

    div.code-toolbar > .toolbar .toolbar-item {
      display: inline-block;
    }

    div.code-toolbar > .toolbar a {
      cursor: pointer;
    }

    div.code-toolbar > .toolbar button {
      background: none;
      border: 0;
      color: inherit;
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-user-select: none; /* for button */
      -moz-user-select: none;
      -ms-user-select: none;
    }

    div.code-toolbar > .toolbar a,
    div.code-toolbar > .toolbar button,
    div.code-toolbar > .toolbar span {
      color: #868686;
      text-transform: uppercase;
      font-size: 12px;
      box-shadow: none;
      border-radius: 0 0 12px 12px;
    }

    p code {
      background-color: #e8f5fd;
      padding: 1px 4px;
      border-radius: 4px;
      font-size: .9em;
    }

    a, a:hover, a:visited, a:active {
      color: #006cae;
      text-decoration: underline;
    }

    li {
      margin-bottom: .5rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  a, a:hover, a:visited, a:active {
    font-weight: 300;
    color: currentColor;
    text-decoration: none;
  }

  .embedVideo-container, .embedVideo-iframe {
    max-width: 100%;
  }

  .main-container {
    padding: 0 24px;

    @media print {
      .fab {
        display: none;
      }
      .question {
        display: none;
      }

      div[class^="Feedback"] {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .main-container {
      padding: 0;
    }
  }

  @media print {

    header {
      display:none;
    }

    h1 {
      margin-top: -100px;
    }
    .sidebar {
      background-color:red;
      display:none;
    }
    #toc2 {
    display: none;
    }

    div[class^="Breadcrumb"] {
    display: none;
    }
    .fab {
      display: none;
    }
  }
`;

const Layout = ({  children }) => {
  const matchRoot = useMatch(`${process.env.SITE_PATH_PREFIX}/`)
  const matchHowToGuides = useMatch(`${process.env.SITE_PATH_PREFIX}/how-to-guides/*`)
  const matchServices = useMatch(`${process.env.SITE_PATH_PREFIX}/services/*`)
  const matchProtected = useMatch(`${process.env.SITE_PATH_PREFIX}/protected/*`)
  const matchAnalytics = useMatch(`${process.env.SITE_PATH_PREFIX}/analytics/*`)
  const fixedHeaderState = React.useState(false)

  const data = useStaticQuery(query)
  const cmcc10Releases = { distributions: data.allCmcc10Release.edges.map(edge => edge.node) }
  const cmcc11Releases = { distributions: data.allCmcc11Release.edges.map(edge => edge.node) }
  const cmcc12Releases = { distributions: data.allCmcc12Release.edges.map(edge => edge.node) }
  var contentArea = "how-to-guides"
  if (matchServices) {
    contentArea = "services"
  } else if (matchProtected) {
    contentArea = "protected"
  } else if (matchAnalytics) {
    contentArea = "analytics"
  }

  return (
    <ReleaseContext.Provider value={{ cmcc10: cmcc10Releases, cmcc11: cmcc11Releases, cmcc12: cmcc12Releases }}>
      <Style>
        <Seo/>
        <Header fixedHeaderState={fixedHeaderState}/>
        <Container
          fluid
          className={clsx({ fixed: fixedHeaderState[0], root: matchRoot })}
        >
          <Row nowrap={'nowrap'}>
            {(matchHowToGuides || matchServices || matchProtected || matchAnalytics) && (
              <Col className={'sidebar'} id={'sidebar'} xs={'content'} style={{ paddingLeft: 0, paddingRight: 0, minWidth: 0}}>
                <Hidden xs sm style={{ width: '22rem' }}>
                  <Sidebar contentArea={contentArea}/>
                </Hidden>
              </Col>
            )}
            <Col style={{ minWidth: 0 }}>
              <main>
                {children}
              </main>
              <Footer/>
            </Col>
          </Row>
        </Container>
        {(matchHowToGuides || matchServices || matchProtected || matchAnalytics) && (
          <Visible xs sm>
            <FullScreenContainer>
              <Sidebar fullscreen={true} contentArea={contentArea}/>
            </FullScreenContainer>
          </Visible>
        )}
      </Style>
    </ReleaseContext.Provider>
  )
}

export default Layout

const query = graphql`
    {
        allCmcc10Release {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
        allCmcc11Release {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
        allCmcc12Release(sort: {fields: aep___version, order: DESC}) {
            edges {
                node {
                    aep {
                        release_webinar_recording
                        release_webinar_slides
                        version
                        whats_new_available
                    }
                    amp {
                        components
                        released
                        version
                    }
                    id
                }
            }
        }
    }
`
